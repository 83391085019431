import { computed } from 'vue';
import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

import { useI18n } from '@/helpers/composables/useI18n';

import { MonetizationType } from '@/@types/graphql-types';
import type { TitleDetail } from '@/interfaces/title-details-graphql';

export interface UseSpinningTextOptions {
	title?: MaybeRefOrGetter<string>;
	offers?: MaybeRefOrGetter<TitleDetail['offers']>;
	seasonNumber?: MaybeRefOrGetter<number | undefined>;
}

const enquote = (text: string, locale: string) => (locale === 'ja' ? `「${text}」` : `"${text}"`);
const toUniqueOfferNames = (offers: TitleDetail['offers'] = []) => [
	...new Set(offers.map(offer => offer.package.clearName)),
];

export const useSpinningText = (options: UseSpinningTextOptions) => {
	const title = computed(() => toValue(options.title) ?? '');
	const offers = computed(() => toValue(options.offers) ?? []);
	const seasonNumber = computed(() => toValue(options.seasonNumber) ?? null);

	const { tc, locale } = useI18n();

	const getOfferTextBlock = (monetization: MonetizationType, before: string, after?: string) => {
		const offerNames = toUniqueOfferNames(offers.value.filter(offer => offer.monetizationType === monetization));
		return `${before} ${offerNames.join(', ')}${after ? ' ' + after : ''}`;
	};

	const getInfoTextBlock = (
		monetization: MonetizationType | 'none',
		beforeTitle: string,
		afterTitle: string,
		afterProviders?: string
	) => {
		const displayedTitle =
			seasonNumber.value == null ? title.value : `${title.value} - ${tc('WEBAPP_SEASON')} ${seasonNumber.value}`;

		const text = `${beforeTitle} ${enquote(displayedTitle, locale.value)} ${afterTitle}`;

		if (monetization === 'none') return text;

		return getOfferTextBlock(monetization, text, afterProviders);
	};

	// for RTL Info Text Block, provider and title need to be swapped 'xxx {provider} xxx {title} xxx'
	const getRTLInfoTextBlock = (
		monetization: MonetizationType,
		beforeProviders: string,
		afterProviders: string,
		afterTitle?: string
	) => {
		const displayedTitle =
			seasonNumber.value == null ? title.value : `${title.value} - ${tc('WEBAPP_SEASON')} ${seasonNumber.value}`;

		const offerNames = toUniqueOfferNames(offers.value.filter(offer => offer.monetizationType === monetization));

		return `${beforeProviders} ${offerNames.join(', ')} ${afterProviders} ${displayedTitle}${
			afterTitle ? ' ' + afterTitle : ''
		}`;
	};

	return {
		getOfferTextBlock,
		getInfoTextBlock,
		getRTLInfoTextBlock,
	};
};
